<template>
    <v-app-bar app dense :flat="$vuetify.breakpoint.lgAndUp">
        <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

        <!-- <v-toolbar-title>{{title}}</v-toolbar-title> -->

        <v-spacer />
        <small class="opacity mr-2">{{ admin && admin.Name }}</small>
        <v-avatar :color="randomColor" size="32" class="white--text">
            {{ admin && admin.NameInitials }}
        </v-avatar>
    </v-app-bar>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        name: "NavbarTop",

        props: {
            value: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            ...mapGetters(["admin","randomColor"]),

            title() {
                return this.$route.meta.title;
            },
        },

        methods: {
            toggleDrawer() {
                this.$emit("input", !this.value);
            }
        },

    }
</script>