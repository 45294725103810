import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify'
import Logger from "@/plugins/logger";
import '@/plugins/firebase';
import '@/registerServiceWorker';

import VueMask from 'v-mask';
import VuePageTransition from 'vue-page-transition';
import Vue2Filters from "vue2-filters";
import VueMoment from "vue-moment";
import * as VueGoogleMaps from 'vue2-google-maps';

import BrandLogo from "@/components/appshell/BrandLogo";
import BrandLogoLoader from "@/components/appshell/BrandLogoLoader";
import InputFieldPercentage from "@/components/global/InputFieldPercentage";
import InputFieldInteger from "@/components/global/InputFieldInteger";
import InputFieldCurrency from "@/components/global/InputFieldCurrency";

Vue.config.productionTip = false

Vue.use(VuePageTransition);
Vue.use(Logger);
Vue.use(VueMoment);
Vue.use(Vue2Filters);
Vue.use(VueMask);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgV5ZAinBA_nCGVapNtEnwE33ezWDH2Ck',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
});

Vue.component("BrandLogo", BrandLogo);
Vue.component("BrandLogoLoader", BrandLogoLoader);
Vue.component("InputFieldPercentage", InputFieldPercentage);
Vue.component("InputFieldInteger", InputFieldInteger);
Vue.component("InputFieldCurrency", InputFieldCurrency);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')