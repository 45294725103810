import store from "@/store";
import axios from "axios";
import axiosCancel from "axios-cancel";

let api_base_uri = () => {
  switch (document.location.host) {
    case "localhost:8080":
      return "https://localhost:44339/";

    case "stg-umtb.invocap.co.uk":
      return "https://digital-mortgage-api-stg.azurewebsites.net";

    default:
      return "https://digital-mortgage-api.azurewebsites.net";
  }
};

let config = {
  baseURL: api_base_uri(),
  timeout: process.env.NODE_ENV === "production" ? 100 * 1000 : 300 * 1000,
};

const _axios = axios.create(config);

axiosCancel(_axios, {
  debug: false,
});

_axios.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    const token = store.getters.token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      window.location.href = "/";
    }

    store.commit("ADD_API_CALL");
    return config;
  },

  function (error) {
    // Do something with request error
    store.commit("REMOVE_API_CALL");
    return window.Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    store.commit("REMOVE_API_CALL");
    return response;
  },
  function (error) {
    // Do something with response error
    store.commit("REMOVE_API_CALL");
    if (error["__CANCEL__"] !== true) {
      return window.Promise.reject(error);
    }
  }
);

export default _axios;
