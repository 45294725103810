<template>
    <v-app :dark="darkTheme">
        <vue-page-transition name="fade-in-down">
            <router-view name="top_nav" class="router-view" v-model="drawer" />
        </vue-page-transition>

        <router-view name="drawer_nav" class="router-view" v-model="drawer" />

        <v-main>
            <v-progress-linear indeterminate color="primary" v-if="loading" />
            <vue-page-transition :name="transitionName">
                <router-view class="router-view" />
            </vue-page-transition>
        </v-main>

        <vue-page-transition name="fade-in-up">
            <router-view name="bottom_nav" class="router-view" />
        </vue-page-transition>

        <Notifications />
    </v-app>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import api from "@/services/api-service";
import Notifications from "@/components/appshell/SysNotifications";

export default {
    name: 'App',
    components: {
        Notifications
    },

    data() {
        return {
            drawer: this.$vuetify.breakpoint.lgAndUp,
            transitionName: "fade-in-left"
        }
    },

    computed: {
        ...mapGetters(["user", "admin", "lender","darkTheme", "updateInProgress", "loading", "randomColor"]),
    },

    watch: {
        darkTheme(v) {
            this.$vuetify.theme.dark = v;
        },
        updateInProgress(v, ov) {
            if (v) {
                //this.$Logger.track(`pwa_update`, {
                //    component: this.$options.name,
                //});
                this.$router.push("/pwa-update");
            }
        },

        async user(u, ov) {
            if (u) {
                this.$Logger.identify(u.uid, {
                    name: u.name,
                    email: u.email,
                    phone: u.phone,
                });

                const admin = await this.getDashBoardUser();
                if (admin) {
                    this._redirect();
                } else {
                    this.$router.replace("/403");
                }

            } else {
                this.$router.replace("/");
            }
        },

        '$route'(to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'fade-in-right' : 'fade-in-left'
        }
    },

    methods: {
        _redirect() {
            let savedRoute = localStorage.getItem("route");
            if (this.$route.fullPath != savedRoute) {
                this.$router.replace(savedRoute || "/pipelines");
            }
        },

        _setDynamicManifest() {
            const base = "https://ukinvocap.blob.core.windows.net/pwa/UMTB";
            const manifestURL = base + '/manifest.json';
            const fabiconURL = base + '/icons/favicon.ico';
            const fabicon32URL = base + '/icons/favicon-32x32.png';
            const fabicon16URL = base + '/icons/favicon-16x16.png';
            const appleIconURL = base + '/icons/apple-icon-144x144.png';

            document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);

            document.querySelector('meta[name="theme-color"]').setAttribute('content', this.$vuetify.theme.themes
                .dark.primary);
            document.querySelector('meta[name="msapplication-TileImage"]').setAttribute('content', appleIconURL);

            document.querySelector('link[rel="icon"]').setAttribute('href', fabiconURL);
            document.querySelector('link[rel="icon"][sizes="32x32"]').setAttribute('href', fabicon32URL);
            document.querySelector('link[rel="icon"][sizes="16x16"]').setAttribute('href', fabicon16URL);
            document.querySelector('link[rel="apple-touch-icon"]').setAttribute('href', appleIconURL);
        },

        async _setUserIPInfo() {
            const info = await this.fetchIPInfoJSON();
            this.$store.commit("UPDATE_IPINFO", info);
        },
        async fetchIPInfoJSON() {
            const response = await fetch('https://ipapi.co/json/');
            const json = await response.json();
            return json;
        },

        async getDashBoardUser() {
            try {
                const encoded_email = encodeURIComponent(this.user.email);
                const encoded_phone = encodeURIComponent(this.user.phone);

                const provider = this.user.provider;
                const url = provider == 'phone' ?
                    `/dashboard-users/by-phone/?phone=${encoded_phone}` :
                    `/dashboard-users/by-email/?email=${encoded_email}`;
                const response = await api.get(url);

                if (response && response.status == 200 && response.data) {
                    let lender_id = response.data.LenderId;
                    this.getLender(lender_id);
                    this.$store.commit("UPDATE_ADMIN", response.data);
                    return response.data;
                }
            } catch (error) {
                if (error.response && error.response.status == 404) {
                    this.$router.push("/403");
                } else {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "getDashBoardUser"
                    }, error);
                }

                return null;
            }
        },

        async getLender(lender_id) {
            try {
                const url = `/lenders/${lender_id}`;
                const response = await api.get(url);

                if (response && response.status == 200 && response.data) {
                    this.$store.commit("UPDATE_LENDER", response.data);
                    return response.data;
                }
            } catch (error) {
                this.$Logger.error({
                    component: this.$options.name,
                    method: "getLender"
                }, error);
                return null;
            }
        },

    },

    mounted() {
        this._setUserIPInfo();
        this._setDynamicManifest();
    },
    created() {
        this.$vuetify.theme.dark = this.darkTheme;
    }
};
</script>

<style lang="scss">
@import "assets/app.scss";
</style>