<template>
  <v-container fill-height>
    <v-row align="center" justify="center" fill-height>
      <v-col cols="12" xl="10">

        <CustomersTable />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
    import CustomersTable from "@/components/customers/CustomersTable"

  export default {
    name: 'Customers',

    components: {
      CustomersTable,
    },
  }
</script>