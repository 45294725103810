import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from "vuetify/es5/util/colors";
import {
  Ripple
} from "vuetify/lib/directives"

Vue.use(Vuetify);

const opts = {
  //   components: {
  //     VApp,
  //     VNavigationDrawer,
  //     VFadeTransition,
  //     VLayout,
  //     VCard,
  //     VCardTitle,
  //     VCardText,
  //     VCardActions,
  //     VSheet
  //   },

  directives: {
    Ripple
  },

  theme: {
    options: {
      customProperties: true,
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      },
    },
    themes: {
      light: {
        primary: "#ea6800",
        secondary: "212121",
        accent: "#ff780b"
      },
      dark: {
        primary: "#ea6800",
        secondary: "666666",
        accent: "#ff780b"
      }
    }
  },

  icons: {
    iconfont: "mdi", // default
    values: {
      pages_dashboard: "mdi-monitor-dashboard",
      pages_pipelines: "mdi-pipe",
      pages_investors: "mdi-briefcase-account-outline",
      pages_credit: "mdi-file-cog-outline",
      pages_reports: "mdi-microsoft-excel",
      pages_collections: "mdi-book-alert-outline",
      pages_partners: "mdi-handshake-outline",
      pages_properties: "mdi-home-city-outline",

      actions_back: "mdi-chevron-left",
      actions_forward: "mdi-chevron-right",
      actions_file: "mdi-file",
      actions_open: "mdi-plus",
      actions_close: "mdi-close",
      actions_add_single: "mdi-plus",
      actions_add: "mdi-playlist-plus",
      actions_edit_single: "mdi-pencil",
      actions_edit: "mdi-playlist-edit",
      actions_send: "mdi-send",
      actions_remove: "mdi-playlist-remove",
      actions_delete: "mdi-delete-outline",
      actions_share: "mdi-share-variant-outline",
      actions_logout: "mdi-logout-variant",
      actions_maximize: "mdi-arrow-expand-right",
      actions_minimize: "mdi-arrow-expand-left",
      actions_expand: "mdi-arrow-expand-all",
      actions_download: "mdi-download",
      actions_export: "mdi-database-export-outline",
      actions_export_xsl: "mdi-microsoft-excel",
      actions_upload: "mdi-upload",
      actions_search: "mdi-magnify",
      actions_schedule: "mdi-calendar",
      actions_link_add: "mdi-link-variant-plus",
      actions_link_remove: "mdi-link-variant-remove",

      global_contact_email: "mdi-at",
      global_contact_phone: "mdi-phone-outline",
      global_cellphone: "mdi-cellphone",
      global_cellphone_sms: "mdi-cellphone-message",
      global_address: "mdi-map-search-outline",
      global_website: "mdi-web",
      global_chat: "mdi-forum-outline",
      global_check: "mdi-check-bold",

      global_light_theme: "mdi-brightness-5",
      global_dark_theme: "mdi-brightness-2",

      global_map_marker: "mdi-map-marker",
      global_required: "mdi-asterisk",
      global_search: "mdi-database-search-outline",
      global_view_dashboard: "mdi-view-dashboard-variant-outline",

      auth_google: "mdi-google",
      auth_apple: "mdi-apple",
      auth_facebook: "mdi-facebook",
      auth_email: "mdi-email-outline",
      auth_sms: "mdi-cellphone",
    }
  }
}

export default new Vuetify(opts);