<template>
    <v-card v-bind="$attrs">
        <v-card-title class="primary--text">
            Loans
            <v-spacer />
            <v-text-field v-model="search" append-icon="$vuetify.icons.global_search" label="Search"
                :placeholder="placeholder" outlined dense clearable hide-details class="shrink ml-1"
                style="min-width:320px;" />
        </v-card-title>
        <v-card-text>
            <v-data-table item-key="id" :headers="headers" :items="items" :options.sync="options" :multi-sort="true"
                :must-sort="false" :server-items-length="total" :footer-props="footerProps">

                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.Id" @click="openLoan(item)">

                            <td v-if="!customer">{{ item.Customer }}</td>
                            <td>{{ item.Type }}</td>
                            <!-- <td>{{ item.TotalAmount | currency('£',0) }}</td> -->
                            <!-- <td>{{ item.InitialLTV | percent(2) }}</td> -->
                            <td>{{ item.PrincipalOutstandingAmount | currency('£', 0) }}</td>
                            <td>{{ item.CurrentLTV | percent(2) }}</td>
                            <td>{{ item.UpdatedOn | moment('YYYY-MM-DD') }}</td>

                        </tr>
                    </tbody>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import {
    mapGetters
} from 'vuex'

import api from "@/services/api-service";
const api_base_uri = "loans";

export default {
    name: "LoansTable",
    inheritAttrs: false,

    props: {
        customer: {
            type: Object,
            required: false
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        showAdd: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            loading: false,
            search: "",
            status: ["Active"],
            selections: [],
            items: [],
            total: 0,

            creditReviewFilter: false,

            options: {
                itemsPerPage: 10,
                sortBy: ["UpdatedOn"],
                sortDesc: [true]
            },
            footerProps: {
                itemsPerPageOptions: [5, 10, 50, 100],
                itemsPerPageText: ""
            }
        };
    },

    computed: {
        ...mapGetters(["admin","lender"]),
        lender_id(){
            return this.lender && this.lender.Id || this.admin && this.admin.LenderId
        },

        placeholder() {
            return this.customer ? "Property Address" : "Customer | Property Address"
        },

        headers() {
            let list = [];

            if (!this.customer) {
                list.push({
                    text: "Customer",
                    value: "Customer",
                    sortable: true,
                    align: "left",
                })
            }

            let append = [{
                text: "Type",
                value: "Type",
                align: "left",
                sortable: true,
            },

            {
                text: "Principal Outstanding",
                value: "PrincipalOutstandingAmount",
                align: "left",
                sortable: false,
            },
            {
                text: "Current LTV",
                value: "CurrentLTV",
                align: "left",
                sortable: false,
            },

            {
                text: "Updated",
                value: "UpdatedOn",
                sortable: true,
            },
            ];

            return [...list, ...append];
        },
        offset() {
            return (this.options.page - 1) * this.options.itemsPerPage;
        },
        limit() {
            return this.options.itemsPerPage;
        },
        sort() {
            let sorts = [];
            let fields = this.options.sortBy;
            let dirs = this.options.sortDesc;
            for (let index = 0; index < fields.length; index++) {
                let field = fields[index];
                let dir = dirs[index] ? "desc" : "";
                sorts.push({
                    Field: field,
                    Dir: dir
                });
            }
            return sorts;
        },
        queryParams() {
            let strParams = `?Offset=${this.offset}&Limit=${this.limit}`;
            if (this.sort && this.sort.length > 0) {
                strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
            }
            if (this.search && this.search.length > 0) {
                strParams = strParams + `&Search=${this.search || ""}`;
            }
            if (this.status != null && this.status.length > 0) {
                strParams = strParams + `&Status=${JSON.stringify(this.status) || ""}`;
            }
            return strParams;
        },
        exportParams() {
            let strParams = `?Offset=0&Limit=100000`;
            if (this.sort && this.sort.length > 0) {
                strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
            }
            if (this.search && this.search.length > 0) {
                strParams = strParams + `&Search=${this.search || ""}`;
            }
            if (this.status != null && this.status.length > 0) {
                strParams = strParams + `&Status=${JSON.stringify(this.status) || ""}`;
            }
            return strParams;
        },
    },

    watch: {
        options: {
            async handler(v) {
                localStorage.setItem(`${this.$options._componentTag}.options`, JSON.stringify(v));
                await this.queryItems();
            },
            deep: true
        },

        status: {
            async handler(v) {
                this.options.page = 1;
                localStorage.setItem(`${this.$options._componentTag}.status`, JSON.stringify(v) || '');
                await this.queryItems();
            },
            deep: true
        },

        creditReviewFilter: {
            async handler(v) {
                this.options.page = 1;
                await this.queryItems();
            },
            deep: true
        },

        search: {
            async handler(v) {
                this.options.page = 1;
                await this.queryItems();
            },
            deep: true
        },
    },

    methods: {
        openLoan(item) {
            this.$router.push(`/loans/${item.Id}`);
        },

        _openBlobFile(blob, filename) {
            if (!window.navigator.msSaveOrOpenBlob) {
                // BLOB NAVIGATOR
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
            } else {
                // BLOB FOR EXPLORER 11
                const url = window.navigator.msSaveOrOpenBlob(blob, filename);
            }
        },


        async fetchSelections() {
            try {
                this.loading = true;
                const response = await api.get(`${api_base_uri}/selections`);
                if (response && response.status == 200) {
                    this.selections = response.data;
                }
            } catch (error) {
                this.$Logger.error({
                    component: this.$options._componentTag,
                    method: "fetchSelections"
                }, error);
                this.$emit("error", true);
            } finally {
                this.loading = false;
            }
        },

        async queryItems() {
            try {
                this.loading = true;

                let url = `/lenders/${this.lender_id}/loans/${this.queryParams}`;
                if (this.customer) {
                    url = `/customers/${this.customer.Id}/loans/${this.queryParams}`;
                }
                const response = await api.get(url);

                if (response && response.status == 200 && response.data) {
                    this.total = response.data.Total;
                    this.items = response.data.Items || [];
                }
            } catch (error) {
                this.$Logger.error({
                    component: this.$options._componentTag,
                    method: "queryItems"
                }, error);
            } finally {
                this.loading = false;
            }
        },

        async exportReport() {
            try {
                this.loading = true;

                const url = `/reports/loans/${this.exportParams}`;
                const response = await api.get(url, {
                    responseType: "blob",
                });

                if (response && response.status == 200 && response.data) {
                    let blob = new Blob([response.data], {
                        type: response.data.type
                    });

                    this._openBlobFile(blob, "loans.csv");
                }

            } catch (error) {
                this.$Logger.error({
                    component: this.$options._componentTag,
                    method: "export"
                }, error);
            } finally {
                this.loading = false;
            }
        },
    },

    mounted() {
        this.fetchSelections();
        const opt = localStorage.getItem(`${this.$options._componentTag}.options`);
        if (opt) {
            this.options = JSON.parse(opt);
        }
        const st = localStorage.getItem(`${this.$options._componentTag}.status`);
        if (st) {
            this.status = st;
        }
    },
}
</script>

<style scoped>
.v-data-table>>>tr:hover {
    cursor: pointer;
}
</style>