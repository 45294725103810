<template>
    <v-img src="https://ukinvocap.blob.core.windows.net/pwa/UMTB/logos/logo.png" v-bind="$attrs" v-if="branded"/>
    <v-img src="https://ukinvocap.blob.core.windows.net/pwa/UMTB/logos/invocap_orange.png" v-bind="$attrs" v-else/>
</template>

<script>
    export default {
        name: "BrandLogo",
        inheritAttrs: false,

        props: {
            branded: {
                type: Boolean,
                default: false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>