<template>
    <!-- <v-text-field type="text" :value="number" @change="updated" v-mask="currencyMask" v-bind="$attrs">
        <template v-slot:append>
            <slot name="append">GBP</slot>
        </template>
    </v-text-field> -->

    <v-text-field type="number" :value="number" @input="updated" v-bind="$attrs">
        <template v-slot:append>
            <slot name="append">GBP</slot>
        </template>
        <template v-slot:prepend-inner>
            <slot name="prepend-inner">£</slot>
        </template>
    </v-text-field>
</template>

<script>
    ///https://github.com/probil/v-mask#readme
    ///https://github.com/text-mask/text-mask/tree/master/addons/#createnumbermask
    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const _currencyMask = createNumberMask({
        prefix: '£'
    });


    export default {
        name: "InputFieldCurrency",
        inheritAttrs: false,

        props: {
            value: {
                type: Number,
                default: 0
            },
        },

        watch: {
            value(v, ov) {
                this.number = v || 0;
            },
        },

        data() {
            return {
                currencyMask: _currencyMask,
                number:  this.value || 0,
            }
        },

        methods: {
            updated(v) {
                // const val = v ? v.replace('£', '').replace(/,/g, '') : 0;
                // const num = val && parseFloat(val);
                // console.log("InputFieldCurrency.updated(v,num)", v, num,this.$attrs.label);
                this.$emit('input', Number(v));
            }
        },
    }
</script>

<style scoped>
    .v-application--is-ltr .v-text-field >>> .v-input__append-inner,
    .v-application--is-ltr .v-text-field >>> .v-input__prepend-inner {
        margin-left: auto;
        padding-left: 4px;
    }
</style>