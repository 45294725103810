<template>
    <v-card v-bind="$attrs" :loading="loading">
        <v-card-title class="primary--text">
            Customers
            <v-spacer />
            <v-text-field v-model="search" append-icon="$vuetify.icons.global_search" label="Search"
                placeholder="Name | Representative" outlined dense clearable hide-details class="shrink ml-1"
                style="min-width:320px;" />
        </v-card-title>

        <v-card-text>
            <v-data-table item-key="id" :headers="headers" :items="items" :options.sync="options" :multi-sort="true"
                :must-sort="false" :server-items-length="total" :loading="loading" :loader-height="2"
                :footer-props="footerProps">

                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="item in items" :key="item.Id" @click="openCustomer(item)">

                            <td>{{ item.Name }}</td>
                            <td>{{ item.Identifier }}</td>
                            <td>{{ item.Email }}</td>
                            <td>{{ item.Phone }}</td>
                            <td>{{ item.UpdatedOn | moment('YYYY-MM-DD') }}</td>

                        </tr>
                    </tbody>
                </template>

            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import api from "@/services/api-service";
    const api_base_uri = "customers";

    export default {
        name: "CustomersTable",
        inheritAttrs: false,

        props: {
            showSelect: {
                type: Boolean,
                default: false
            },
            showAdd: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                loading: false,

                search: "",
                status: "Active",
                selections: [],
                items: [],
                total: 0,

                options: {
                    itemsPerPage: 10,
                    sortBy: ["UpdatedOn"],
                    sortDesc: [true]
                },
                footerProps: {
                    itemsPerPageOptions: [5, 10, 50, 100],
                    itemsPerPageText: ""
                }
            };
        },

        computed: {
            headers() {
                let list = [
                    {
                        text: "Name",
                        value: "Name",
                        align: "left",
                    },

                    {
                        text: "Identifier",
                        value: "Identifier",
                        align: "left",
                    },

                    {
                        text: "Email",
                        value: "Email",
                        align: "left",
                    },

                    {
                        text: "Phone",
                        value: "Phone",
                        align: "left",
                    },
                     {
                        text: "Updated",
                        value: "UpdatedOn"
                    },
                ];

                return list;
            },
            offset() {
                return (this.options.page - 1) * this.options.itemsPerPage;
            },
            limit() {
                return this.options.itemsPerPage;
            },
            sort() {
                let sorts = [];
                let fields = this.options.sortBy;
                let dirs = this.options.sortDesc;
                for (let index = 0; index < fields.length; index++) {
                    let field = fields[index];
                    let dir = dirs[index] ? "desc" : "";
                    sorts.push({
                        Field: field,
                        Dir: dir
                    });
                }
                return sorts;
            },
            queryParams() {
                let strParams = `?Offset=${this.offset}&Limit=${ this.limit }`;
                if (this.sort && this.sort.length > 0) {
                    strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
                }
                if (this.search && this.search.length > 0) {
                    strParams = strParams + `&Search=${this.search || ""}`;
                }
                if (this.status != null && this.status.length > 0) {
                    strParams = strParams + `&Status=${this.status || ""}`;
                }
                return strParams;
            },
            exportParams() {
                let strParams = `?Offset=0&Limit=100000`;
                if (this.sort && this.sort.length > 0) {
                    strParams = strParams + `&Sort=${JSON.stringify(this.sort)}`;
                }
                if (this.search && this.search.length > 0) {
                    strParams = strParams + `&Search=${this.search || ""}`;
                }
                if (this.status != null && this.status.length > 0) {
                    strParams = strParams + `&Status=${this.status || ""}`;
                }
                return strParams;
            },
        },

        watch: {
            options: {
                async handler(v) {
                    localStorage.setItem(`${this.$options._componentTag}.options`, JSON.stringify(v));
                    await this.queryItems();
                },
                deep: true
            },

            status: {
                async handler(v) {
                    this.options.page = 1;
                    localStorage.setItem(`${this.$options._componentTag}.status`, v || '');
                    await this.queryItems();
                },
                deep: true
            },

            search: {
                async handler(v) {
                    this.options.page = 1;
                    await this.queryItems();
                },
                deep: true
            },
        },

        methods: {
            openCustomer(item) {
                this.$router.push(`customers/${item.Id}`);
            },

            _openBlobFile(blob, filename) {
                if (!window.navigator.msSaveOrOpenBlob) {
                    // BLOB NAVIGATOR
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    // BLOB FOR EXPLORER 11
                    const url = window.navigator.msSaveOrOpenBlob(blob, filename);
                }
            },


            async fetchSelections() {
                try {
                    this.loading = true;
                    const response = await api.get(`${api_base_uri}/selections`);
                    if (response && response.status == 200) {
                        this.selections = response.data;
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options._componentTag,
                        method: "fetchSelections"
                    }, error);
                    this.$emit("error", true);
                } finally {
                    this.loading = false;
                }
            },

            async queryItems() {
                try {
                    this.loading = true;

                    const url = `/${api_base_uri}/${this.queryParams}`;
                    const response = await api.get(url);

                    if (response && response.status == 200 && response.data) {
                        this.total = response.data.Total;
                        this.items = response.data.Items || [];
                    }
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options._componentTag,
                        method: "queryItems"
                    }, error);
                } finally {
                    this.loading = false;
                }
            },

            async exportReport() {
                try {
                    this.loading = true;

                    const url = `/reports/customers/${this.exportParams}`;
                    const response = await api.get(url, {
                        responseType: "blob",
                    });

                    if (response && response.status == 200 && response.data) {
                        let blob = new Blob([response.data], {
                            type: response.data.type
                        });

                        this._openBlobFile(blob, "customers.csv");
                    }

                } catch (error) {
                    this.$Logger.error({
                        component: this.$options._componentTag,
                        method: "export"
                    }, error);
                } finally {
                    this.loading = false;
                }
            },
        },

        mounted() {
            this.fetchSelections();
            const opt = localStorage.getItem(`${this.$options._componentTag}.options`);
            if (opt) {
                this.options = JSON.parse(opt);
            }
            const st = localStorage.getItem(`${this.$options._componentTag}.status`);
            if (st) {
                this.status = st;
            }
        },
    }
</script>