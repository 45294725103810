import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store';

import NavbarTop from "@/components/appshell/NavbarTop.vue";
import NavDrawer from "@/components/appshell/NavDrawer.vue";

import Home from "@/views/Home.vue";
import Pipelines from "@/views/Pipelines.vue";
import Customers from "@/views/Customers.vue";
import VersionUpdate from "@/views/VersionUpdate.vue";

Vue.use(VueRouter)

const routes = [{
    path: "*",
    name: "NotFound",
    component: () => import("@/views/404.vue"),
    meta: {
      secured: false,
      title: "Page Not Found"
    }
  },

  {
    path: '/403',
    name: 'Unauthorized',
    component: () => import("@/views/403.vue"),
    meta: {
      secured: false,
      title: 'Unauthorized access to Digital Mortgages by Invocap',
    },
  },

  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
    },
    meta: {
      secured: false,
      title: 'Digital Mortgages by Invocap',
    },
  },

  {
    path: '/pwa-update',
    name: 'VersionUpdate',
    components: {
      default: VersionUpdate,
    },
    meta: {
      secured: false,
      // saveRoute: true,
      title: 'Version Update.',
    },
  },

  
  {
    path: '/passwordless',
    name: 'PasswordlessLoginBySMS',
    components: {
      default: () => import("@/views/PasswordlessLogin.vue"),
    },
    meta: {
      secured: false,
      title: 'Digital Mortgages by Invocap',
    },
  },

  {
    path: '/passwordless-link',
    name: 'PasswordlessLoginByEmail',
    components: {
      default: () => import("@/views/PasswordlessLogin_email.vue"),
    },
    meta: {
      secured: false,
      title: 'Digital Mortgages by Invocap',
    },
  },

  {
    path: '/passwordless-cb',
    name: 'PasswordlessCallback',
    components: {
      default: () => import("@/views/PasswordlessCallback.vue"),
    },
    meta: {
      secured: false,
      title: 'Digital Mortgages by Invocap',
    },
  },

  //{
  //  path: '/customers',
  //  name: 'Customers',
  //  components: {
  //    default: Customers,
  //    top_nav: NavbarTop,
  //    // bottom_nav: NavbarBottom,
  //    drawer_nav: NavDrawer
  //  },
  //  meta: {
  //    secured: true,
  //    saveRoute: true,
  //    title: 'Customers',
  //  },
  //},

  //{
  //  path: '/customers/:id',
  //  name: 'Customer',
  //  components: {
  //    default: () => import('@/views/Customer.vue'),
  //    top_nav: NavbarTop,
  //    // bottom_nav: NavbarBottom,
  //    drawer_nav: NavDrawer
  //  },
  //  meta: {
  //    secured: true,
  //    saveRoute: true,
  //    title: 'Customer',
  //  },
  //},

  {
    path: '/pipelines',
    name: 'Pipelines',
    components: {
      default: Pipelines,
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: 'Loans',
    },
  },

  {
    path: '/loans/:id',
    name: 'Loan',
    components: {
      default: () => import('@/views/Loan.vue'),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: 'Loan',
    },
  },

  // {
  //   path: '/reports',
  //   name: 'Reports',
  //   components: {
  //     default: () => import('@/views/Reports.vue'),
  //     top_nav: NavbarTop,
  //     // bottom_nav: NavbarBottom,
  //     drawer_nav: NavDrawer
  //   },
  //   meta: {
  //     secured: true,
  //     saveRoute: true,
  //     title: 'Reports',
  //   },
  // },

  {
    path: '/properties',
    name: 'Properties',
    components: {
      default: () => import('@/views/Properties.vue'),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: 'Properties',
    },
  },

  {
    path: '/properties/:id',
    name: 'Property',
    components: {
      default: () => import('@/views/Property.vue'),
      top_nav: NavbarTop,
      // bottom_nav: NavbarBottom,
      drawer_nav: NavDrawer
    },
    meta: {
      secured: true,
      saveRoute: true,
      title: 'Property',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeResolve((to, from, next) => {
  let title = to.meta && to.meta.title ? to.meta.title : to.name;
  document.title = title;

  if (to.meta && to.meta.saveRoute) {
    localStorage.setItem("route", to.fullPath);
  }

  if (to.meta && to.meta.secured) {
    const authorized = store.getters["authorized"];
    if (authorized) {
      next();
    } else {
      store.commit("UPDATE_USER",null);
      store.commit("UPDATE_ADMIN",null);
      next("/");
    }
  } else {
    next();
  }
});

export default router