<template>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app id="global-drawer">
        <v-card flat tile dark fill-height class="card-flex">
            <v-card-text class="pos-r text-center" style="overflow:hidden">
                <BrandLogoLoader :size="mini ? 28 : 120" :gutter="5" :width="5" :infinite="loading" ripple branded />
            </v-card-text>

            <!-- <v-card-text class="py-10">
                <v-btn icon small v-if="mini">
                    <v-icon>$vuetify.icons.actions_search</v-icon>
                </v-btn>
                <CustomersAutocomplete v-model="customer" filled rounded dense hide-details placeholder="Customer Search" color="black" v-else />
            </v-card-text> -->

            <v-card-text class="pa-0">
                <v-list dense>
                    <v-list-item link to="/pipelines">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_pipelines</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Loans
                        </v-list-item-content>

                    </v-list-item>

                    <!-- <v-list-item link to="/customers">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_dashboard</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Customers
                        </v-list-item-content>

                    </v-list-item> -->


                    <v-list-item link to="/properties">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_properties</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Properties
                        </v-list-item-content>

                    </v-list-item>

                </v-list>
            </v-card-text>

            <v-divider />

            <v-card-text class="pa-0">
                <v-list dense>
                    <v-list-item @click.stop="dark = !dark">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.{{ dark ?"global_light_theme" : "global_dark_theme"  }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            {{ dark ? "Bright" : "Dark" }}
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item @click.stop="mini = !mini">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.{{ mini ? "actions_maximize" :"actions_minimize" }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            {{ mini ? "Maximize" : "Minimize" }}
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item @click="signOut" class="mt-5">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.actions_logout</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Log-Out
                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>

        <v-card flat tile dark class="card-center">
            <v-card-actions v-if="!mini">
                <div class="text-left mr-5" style="font-size:0.72rem;">
                    <span>Powered by Invocap {{ year }}</span><br />
                    <a href="https://ukinvocap.blob.core.windows.net/documents/Privacy_Policy_23-05-18.pdf"
                        target="_blank">Terms of use & Privacy policy</a>
                </div>

                <v-avatar :size="32">
                    <v-img src="https://ukinvocap.blob.core.windows.net/pwa/UMTB/logos/invocap_orange.png" />
                    <!-- <v-img src="https://ukinvocap.blob.core.windows.net/marketing/logo.png" /> -->
                </v-avatar>
            </v-card-actions>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import {
        getAuth,
        signOut
    } from "firebase/auth";

    import CustomersAutocomplete from '@/components/customers/CustomersAutocomplete';

    export default {
        name: "NavDrawer",

        components: {
            CustomersAutocomplete
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                drawer: this.value,
                dark: this.darkTheme,
                mini: this.miniDrawer,
                customer: null
            }
        },

        watch: {
            customer(v, ov) {
                if (!v) {
                    return;
                }
                window.location.href = `/customers/${v.Id}`;
            },
            drawer(v, ov) {
                this.$emit("input", v);
            },
            mini(v, ov) {
                this.$store.commit("UPDATE_MINI", v);
            },
            dark(v, ov) {
                this.$store.commit("UPDATE_DARK", v);
            },
            value(v, ov) {
                this.drawer = v;
            }
        },

        computed: {
            ...mapGetters(["user", "darkTheme", "miniDrawer", "loading"]),

            year() {
                return new Date().getFullYear();
            },
        },

        methods: {
            async signOut() {
                try {
                    const auth = getAuth();
                    let result = await signOut(auth);
                    // console.log("signOut:", result);
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options._componentTag,
                        method: "signOut"
                    }, error);

                    // console.log("signOut.error:", error);
                }
            }
        },

        mounted() {
            this.mini = this.miniDrawer;
            this.dark = this.darkTheme;
        },
    }
</script>

<style lang="css" scoped>
    #global-drawer>>>.v-navigation-drawer__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: end;
    }

    #btn_share>>>p {
        margin: 0;
    }

    #btn_share>>>p::after {
        content: ' with Friends'
    }
</style>