<template>
    <v-progress-circular :size="size" :width="width" :color="color" :indeterminate="infinite" class="circle"
        :class="{'ripple': ripple && infinite, 'dark':dark}">
        <v-avatar :size="logoSize" v-bind="$attrs">
            <BrandLogo :branded="branded"/>
        </v-avatar>
    </v-progress-circular>
</template>

<script>
    export default {
        inheritAttrs: false,
        name: "BrandLogoLoader",

        props: {
            size: {
                type: Number,
                default: 150
            },
            width: {
                type: Number,
                default: 2
            },
            gutter: {
                type: Number,
                default: 10
            },
            color: {
                type: String,
                default: "primary"
            },
            dark: {
                type: Boolean,
                default: true
            },
            infinite: {
                type: Boolean,
                default: true
            },
            ripple: {
                type: Boolean,
                default: false
            },
            branded: {
                type: Boolean,
                default: false
            },
        },

        computed: {
            logoSize() {
                return this.size - this.width - this.gutter;
            }
        },
    }
</script>

<style lang="scss" scoped>
    $light-color:#212121;
    $dark-color:#e1e1e1;

    .ripple {
        margin: auto;
        width: 1rem;
        height: 1rem;
        animation: ripple 1s linear infinite;
        z-index: 0;

        &.circle {
            border-radius: 50%;
        }

        &.dark {
            animation: ripple-dark 1s linear infinite;
        }
    }

    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 1rem rgba($light-color, 0.35);
        }

        100% {
            box-shadow: 0 0 0 18rem rgba($light-color, 0);
        }
    }

    @keyframes ripple-dark {
        0% {
            box-shadow: 0 0 0 1rem rgba($dark-color, 0.35);
        }

        100% {
            box-shadow: 0 0 0 18rem rgba($dark-color, 0);
        }
    }
</style>