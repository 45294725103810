const host = location.host;
const environment = process.env.NODE_ENV;
const isProductionEnvironment = environment == "production" && host != "localhost:8080";
import LogRocket from 'logrocket';


if (isProductionEnvironment) {
  LogRocket.init('vm8nta/digital-mortgage-mng', {
    rootHostname: 'invocap.com',
    console: {
      isEnabled: isProductionEnvironment,
      shouldAggregateConsoleErrors: isProductionEnvironment,
    },
  });
}

class LogWrapper {
  identify(uid, data) {
    if (isProductionEnvironment) {
      LogRocket.identify(uid, data);
    } else {
      console.log("Logger.identify(uid, data):", uid, data);
    }
  }

  info(text) {
    const model = {
      text: text,
      pageUrl: document.location.href,
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      LogRocket.log('info', model);
    } else {
      console.info("Logger.info(text):", model);
    }
  }

  error(data, err) {

    const model = {
      ...data,
      pageUrl: document.location.href,
      timestamp: new Date().toISOString(),
    };

    if (isProductionEnvironment) {
      // LogRocket:
      LogRocket.captureException(err, model);
    } else {
      console.log("Logger.error(data, err):", model, err);
    }
  }
}

const Logger = {
  install(Vue, options) {
    Vue.prototype.$Logger = new LogWrapper();
  }
};

export default Logger;