<template>
  <v-container fill-height>
    <v-row align="center" justify="center" fill-height>
      <v-col cols="12" xl="10">

        <LoansTable />

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LoansTable from "@/components/loans/LoansTable"

  export default {
    name: 'Pipelines',
    
    components: {
      LoansTable,
    },
  }
</script>