<template>
    <v-text-field type="number" step="0.1" min="0" max="100" :value="percentage" @change="updated" v-bind="$attrs">
        <template v-slot:prepend-inner>%</template>
    </v-text-field>
</template>

<script>
    export default {
        name: "InputFieldPercentage",
        inheritAttrs: false,

        props: {
            value: {
                type: Number,
                default: 0
            },
        },

        watch: {
            value(v, ov) {
                this.percentage = v && Number(v) * 100 || 0;
            }
        },

        data() {
            return {
                percentage: this.value && Number(this.value) * 100 || 0
            }
        },

        methods: {
            updated(v) {
                this.$emit('input', v / 100);
            }
        },
    }
</script>
<style scoped>
    .v-application--is-ltr .v-text-field >>> .v-input__append-inner,
    .v-application--is-ltr .v-text-field >>> .v-input__prepend-inner {
        margin-left: auto;
        padding-left: 4px;
    }
</style>